<template>
  <div>
    <div class="mt-1">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {},
  data () {
    return {}
  },
  created () {

  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
