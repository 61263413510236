<template>
  <div class="p-4">
    <vx-card class="mb-10 border" title="Configurations">
      <div class="mb-5">
        <label>student</label>
        <v-select
          v-model="student"
          label="name"
          :options="students"
          :loading="loadingStudents" />
      </div>
      <div class="mb-5">
        <label>title line 1</label> <vs-input v-model="titleLine1" />
      </div>
      <div class="mb-5">
        <label>title line 2</label> <vs-input v-model="titleLine2" />
      </div>
      <vs-checkbox class="mb-5" v-model="showLogo"> show logo </vs-checkbox>

      <div class="mb-5">
        <label class="block">Date</label>
        <flat-pickr
          v-model="date"
          label="date"
          placeholder="Date"
          :config="configdatePicker"
          class="mb-5" />
      </div>
      <vs-button
        color="#262979"
        class="block mr-0 ml-auto mt-6"
        @click="printPDF"
        type="filled"
        >Print PDF</vs-button
      >
    </vx-card>
    <div class="reportsText">
      <div class="container" style="padding: 25px" ref="printMe1">
        <vs-row style="margin: 10px 0" vs-align="center">
          <vs-col vs-justify="start" vs-align="center" vs-w="6">
            <img
              class="logoContainDiv"
              :src="logoImg"
              :alt="`${$t('basic.Logo')}`"
              v-if="showLogo" />
          </vs-col>
          <vs-col vs-justify="start" vs-align="center" vs-w="6">
            <h2 class="text-primary">{{ titleLine1 }}</h2>
            <h2 class="text-primary">{{ titleLine2 }}</h2>
            <span>{{ date }}</span>
          </vs-col>
        </vs-row>
        <vs-row style="margin-top: 30px" class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Student Name: </span>
          </vs-col>
          <vs-col vs-w="9">
            {{ student ? student.name : '' }}
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Grade: </span>
          </vs-col>
          <vs-col vs-w="9">
            {{ student ? student.class : '' }}
          </vs-col>
        </vs-row>
        <pre style="margin-top: 30px">
Dear Parents,
An education at {{
            institutionLongName
          }}  is about actively combining challenging and enriching experiences
with academic rigor and creative opportunities. We want our students to have the courage to push back the
boundaries of their experience and to explore the possibilities that are available to them. Whilst we are proud of their
academic results, there is so much more to the education we provide. It is, therefore, important that parents
wholeheartedly support the ethos of the school.
Whether it is in the classroom, on the stage, whilst participating in an expedition, on the sports field or when serving
others, we want our students to experience the excitement of discovering they are capable of achieving far more than
they ever felt was possible. We have high expectations of our students and they, in turn, have high expectations of
themselves.
On this note, I would like to present the First report card of this academic year. Let’s work together to create an
environment for true development.
We look forward to the coming academic year.
Kindest Regards
{{ institutionShortName }}
    </pre
        >
        <vs-row style="margin: 30px 0" vs-align="center">
          <vs-col vs-w="9">
            <p>Randa Gamal</p>
            <p class="text-primary font-bold">MYP Coordinator</p>
          </vs-col>
          <vs-col vs-w="2">
            <p>Yasmin Hamoda</p>
            <p class="text-primary font-bold">School Head</p>
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="10">
            <p class="text-center">Powered By Schoolia</p>
          </vs-col>
          <vs-col vs-w="1">
            <p class="text-primary font-bold">Page 1 of 5</p>
          </vs-col>
        </vs-row>
      </div>
      <hr style="margin-top: 25px" />
      <div class="container" style="padding: 25px" ref="printMe2">
        <div class="learningContainDiv"></div>
        <h2 class="" style="page-break-before: always">IB Learner Profile</h2>
        <vs-row style="margin-top: 30px" class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Inquirers </span>
          </vs-col>
          <vs-col vs-w="9">
            We nurture our curiosity, developing skills for inquiry and
            research. We know how to learn independently and with others. We
            learn with enthusiasm and sustain our love of learning throughout
            life.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Knowledgeable </span>
          </vs-col>
          <vs-col vs-w="9">
            We develop and use conceptual understanding, exploring knowledge
            across a range of disciplines. We engage with issues and ideas that
            have local and global significance.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Thinkers </span>
          </vs-col>
          <vs-col vs-w="9">
            We use critical and creative thinking skills to analyse and take
            responsible action on complex problems. We exercise initiative in
            making reasoned, ethical decisions.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Communicators </span>
          </vs-col>
          <vs-col vs-w="9">
            We express ourselves confidently and creatively in more than one
            language and in many ways. We collaborate effectively, listening
            carefully to the perspectives of other individuals and groups.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Principled </span>
          </vs-col>
          <vs-col vs-w="9">
            We act with integrity and honesty, with a strong sense of fairness
            and justice, and with respect for the dignity and rights of people
            everywhere. We take responsibility for our actions and their
            consequences.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Open-minded </span>
          </vs-col>
          <vs-col vs-w="9">
            We critically appreciate our own cultures and personal histories, as
            well as the values and traditions of others. We seek and evaluate a
            range of points of view, and we are willing to grow from the
            experience.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Caring </span>
          </vs-col>
          <vs-col vs-w="9">
            We show empathy, compassion and respect. We have a commitment to
            service, and we act to make a positive difference in the lives of
            others and in the world around us.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Risk-takers </span>
          </vs-col>
          <vs-col vs-w="9">
            We approach uncertainty with forethought and determination; we work
            independently and cooperatively to explore new ideas and innovative
            strategies. We are resourceful and resilient in the face of
            challenges and change.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Balanced </span>
          </vs-col>
          <vs-col vs-w="9">
            We understand the importance of balancing different aspects of our
            lives — intellectual, physical, (spiritual) and emotional — to
            achieve well-being for ourselves and others. We recognise our
            interdependence with other people and with the world in which we
            live.
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="2">
            <span class="text-primary font-bold">Reflective </span>
          </vs-col>
          <vs-col vs-w="9">
            We thoughtfully consider the world and our own ideas and experience.
            We work to understand our strengths and weaknesses in order to
            support our learning and personal development.
          </vs-col>
        </vs-row>
        <vs-row style="margin: 30px 0" vs-align="center">
          <vs-col vs-w="9">
            <p>Randa Gamal</p>
            <p class="text-primary font-bold">MYP Coordinator</p>
          </vs-col>
          <vs-col vs-w="2">
            <p>Yasmin Hamoda</p>
            <p class="text-primary font-bold">School Head</p>
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="10">
            <p class="text-center">Powered By Schoolia</p>
          </vs-col>
          <vs-col vs-w="1">
            <p class="text-primary font-bold">Page 2 of 5</p>
          </vs-col>
        </vs-row>
      </div>
      <hr style="margin: 20px 0" />
      <div class="container" style="padding: 25px" ref="printMe3">
        <vs-table class="table-no-scroll" :data="students">
          <template slot="header">
            <h3 class="text-primary mb-4">Summary of Achievement</h3>
          </template>
          <template slot="thead">
            <vs-th> Quarter 1 </vs-th>
            <vs-th> Achievement Levels </vs-th>
            <vs-th> Final Grade </vs-th>
            <vs-th> Grade </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr v-for="(val, key) in student" :key="key">
              <vs-td v-if="val.subject">
                {{ val.subject }}
              </vs-td>
              <vs-td v-if="val.subject">
                <vs-row>
                  <vs-col
                    vs-w="3"
                    v-for="(level, name, indexLevel) in val.levels"
                    :key="indexLevel">
                    <p>{{ name }}</p>
                    <p class="font-bold">
                      {{ level }}
                    </p>
                  </vs-col>
                </vs-row>
              </vs-td>
              <vs-td v-if="val.subject">
                {{ val.grade }}
              </vs-td>
              <vs-td v-if="val.subject">
                {{ computeGrade(val.grade) }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row style="margin: 30px 0" vs-align="center">
          <vs-col vs-w="9">
            <p>Randa Gamal</p>
            <p class="text-primary font-bold">MYP Coordinator</p>
          </vs-col>
          <vs-col vs-w="2">
            <p>Yasmin Hamoda</p>
            <p class="text-primary font-bold">School Head</p>
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="10">
            <p class="text-center">Powered By Schoolia</p>
          </vs-col>
          <vs-col vs-w="1">
            <p class="text-primary font-bold">Page 3 of 5</p>
          </vs-col>
        </vs-row>
      </div>
      <hr style="margin: 20px 0" />
      <div class="container" style="padding: 25px" ref="printMe4">
        <vs-table class="table-no-scroll" :data="descriptors">
          <template slot="header">
            <h3 class="text-primary mb-4">Grade Descriptors</h3>
          </template>
          <template slot="thead">
            <vs-th> Final Grade </vs-th>
            <vs-th> Grade </vs-th>
            <vs-th> Descriptor </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr v-for="desc in descriptors" :key="desc.grade">
              <vs-td>
                {{ desc.finalGrade }}
              </vs-td>
              <vs-td>
                {{ desc.grade }}
              </vs-td>
              <vs-td>
                {{ desc.Descriptor }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row style="margin: 30px 0" vs-align="center">
          <vs-col vs-w="9">
            <p>Randa Gamal</p>
            <p class="text-primary font-bold">MYP Coordinator</p>
          </vs-col>
          <vs-col vs-w="2">
            <p>Yasmin Hamoda</p>
            <p class="text-primary font-bold">School Head</p>
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="10">
            <p class="text-center">Powered By Schoolia</p>
          </vs-col>
          <vs-col vs-w="1">
            <p class="text-primary font-bold">Page 4 of 5</p>
          </vs-col>
        </vs-row>
      </div>
      <div class="container" style="padding: 25px" ref="printMe5">
        <vs-table
          class="table-no-scroll"
          style="margin-top: 20px"
          :data="Approaches">
          <template slot="header">
            <h3 class="text-primary mb-4">Approaches to Learning</h3>
          </template>
          <template slot="thead">
            <vs-th> Grade </vs-th>
            <vs-th> Descriptor </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr v-for="desc in Approaches" :key="desc.grade">
              <vs-td>
                {{ desc.grade }}
              </vs-td>
              <vs-td>
                {{ desc.descriptor }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row style="margin: 30px 0" vs-align="center">
          <vs-col vs-w="9">
            <p>Randa Gamal</p>
            <p class="text-primary font-bold">MYP Coordinator</p>
          </vs-col>
          <vs-col vs-w="2">
            <p>Yasmin Hamoda</p>
            <p class="text-primary font-bold">School Head</p>
          </vs-col>
        </vs-row>
        <vs-row class="reportRow" vs-align="center">
          <vs-col vs-w="10">
            <p class="text-center">Powered By Schoolia</p>
          </vs-col>
          <vs-col vs-w="1">
            <p class="text-primary font-bold">Page 5 of 5</p>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </div>
</template>
<script>
// import jsPDF from 'jspdf'
import Axios from 'axios'
import vSelect from 'vue-select'
import FlatPickr from 'vue-flatpickr-component'

export default {
  components: {
    'v-select': vSelect,
    FlatPickr
  },
  data: () => ({
    student: null,
    students: [],
    titleLine1: 'New Vision International School',
    titleLine2: 'Quarter 2 Report - February 2022',
    loadingStudents: false,
    showLogo: true,
    date: '',
    configdatePicker: {
      dateFormat: 'M d, yy'
    },
    descriptors: [
      {
        finalGrade: '7',
        grade: '93-100%',
        Descriptor:
          'Produces high-quality, frequently innovative work. Communicates comprehensive, nuanced understandingof concepts and contexts. Consistently demonstrates sophisticated critical and creative thinking. Frequently transfers knowledge and skills with independence and expertise in a variety of complex classroom and realworld situations.'
      },
      {
        finalGrade: '6',
        grade: '89-92%',
        Descriptor:
          'Produces high-quality, occasionally innovative work. Communicates extensive understanding of concepts and contexts. Demonstrates critical and creative thinking, frequently with sophistication. Uses knowledge and skills in familiar and unfamiliar classroom and realworld situations, often with independence.'
      },
      {
        finalGrade: '5',
        grade: '79-88%',
        Descriptor:
          'Produces generally high-quality work. Communicates secure understanding of concepts and contexts. Demonstrates critical and creative thinking, sometimes with sophistication. Uses knowledge and skills in familiar classroom and real-world situations and, with support, some unfamiliar real-world situations.'
      },
      {
        finalGrade: '4',
        grade: '69-78%',
        Descriptor:
          'Produces good-quality work. Communicates basic understanding of most concepts and contexts with few misunderstandings and minor gaps. Often demonstrates basic critical and creative thinking. Uses knowledge and skills with some flexibility in familiar classroom situations, but requires support in unfamiliar situations.'
      },
      {
        finalGrade: '3',
        grade: '60-68%',
        Descriptor:
          'Produces work of an acceptable quality. Communicates basic understanding of many concepts and contexts, with occasionally significant misunderstandings or gaps. Begins to demonstrate some basic critical and creative thinking. Is often inflexible in the use of knowledge and skills, requiring support even in familiar classroom situations.'
      },
      {
        finalGrade: '2',
        grade: '50-59%',
        Descriptor:
          'Produces work of limited quality. Expresses misunderstandings or significant gaps in understanding for many concepts and contexts. Infrequently demonstrates critical or creative thinking. Generally inflexible in the use of knowledge and skills, infrequently applying knowledge and skills.'
      },
      {
        finalGrade: '1',
        grade: '0-49%',
        Descriptor:
          'Produces work of very limited quality. Conveys many significant misunderstandings or lacks understanding of most concepts and contexts. Very rarely demonstrates critical or creative thinking. Very inflexible, rarely using knowledge or skills.'
      },
      {
        finalGrade: 'N/A',
        grade: '',
        Descriptor: 'Not Yet Assessed'
      }
    ],
    Approaches: [
      {
        grade: 'EE',
        descriptor:
          'Exceeding Expectations: The student’s progress against the ATL is exceeding expectations'
      },
      {
        grade: 'ME',
        descriptor:
          'Meeting Expectations: The student’s progress against the ATL is meeting expectations'
      },
      {
        grade: 'AE',
        descriptor:
          'Approaching Expectations: The student’s progress against the ATL is approaching expectations.'
      },
      {
        grade: 'BE',
        descriptor:
          'Below Expectations: The student’s progress against the ATL is below expectations.'
      }
    ],

    // pdf print
    printElement1: null,
    printElement2: null,
    printElement3: null,
    printElement4: null,
    printElement5: null,
    printElementDimension: {
      width: null,
      height: null
    }
  }),
  mounted() {
    this.getAllData()
  },
  methods: {
    async printPDF() {
      this.printElement1 = this.$refs.printMe1
      this.printElement2 = this.$refs.printMe2
      this.printElement3 = this.$refs.printMe3
      this.printElement4 = this.$refs.printMe4
      this.printElement5 = this.$refs.printMe5
      const printElement1Dimension = {
        width: this.printElement1.clientWidth * 0.5,
        height: this.printElement1.clientHeight * 0.5
      }
      const printElement2Dimension = {
        width: this.printElement2.clientWidth * 0.5,
        height: this.printElement2.clientHeight * 0.5
      }
      const printElement3Dimension = {
        width: this.printElement3.clientWidth * 0.5,
        height: this.printElement3.clientHeight * 0.5
      }
      const printElement4Dimension = {
        width: this.printElement4.clientWidth * 0.5,
        height: this.printElement4.clientHeight * 0.5
      }
      const printElement5Dimension = {
        width: this.printElement5.clientWidth * 0.5,
        height: this.printElement5.clientHeight * 0.5
      }
      const page1 = await this.html2PdfBase64NoPrint(this.printElement1)
      const page2 = await this.html2PdfBase64NoPrint(this.printElement2)
      const page3 = await this.html2PdfBase64NoPrint(this.printElement3)
      const page4 = await this.html2PdfBase64NoPrint(this.printElement4)
      const page5 = await this.html2PdfBase64NoPrint(this.printElement5)
      const imgs = [page1, page2, page3, page4, page5]
      const dim = [
        printElement1Dimension,
        printElement2Dimension,
        printElement3Dimension,
        printElement4Dimension,
        printElement5Dimension
      ]
      this.imgs2Pdf(imgs, dim, this.pdfOptions)
    },
    getAllData() {
      this.loadingStudents = true
      Axios.get('student/grades')
        .then((res) => {
          console.log('students', res)
          this.students = res.data
          this.student = res.data[0]
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => {
          this.loadingStudents = false
        })
    },
    computeGrade(grade) {
      let str = ''
      if (grade == 7) {
        str = '93-100%'
      } else if (grade == 6) {
        str = '89-92%'
      } else if (grade == 5) {
        str = '79-88%'
      } else if (grade == 4) {
        str = '69-78%'
      } else if (grade == 3) {
        str = '60-68%'
      } else if (grade == 2) {
        str = '50-59%'
      } else if (grade == 1) {
        str = '0-49%'
      } else {
        str = 'Not Yet Assessed'
      }
      return str
    }
  },
  computed: {
    pdfOptions() {
      const opt = {
        orientation: 'portrait',
        unit: 'pt',
        fileName: `${this.student?.nickname || 'no student selected'}`,
        format: {}
      }
      return opt
    }
  },
  created() {
    this.titleLine1 = this.institutionLongName || this.siteTitle
  }
}
</script>

<style lang="scss">
@import './pdfStyle';

.logoContainDiv {
  // background-image: url("../../assets/images/logo/logo.png");
  height: 60px;
}
</style>
