<template>
  <div class="iframe-container">
    <meta charset="utf-8">
    <link type="text/css" rel="stylesheet" href="https://dmogdx0jrul3u.cloudfront.net/1.3.7/css/bootstrap.css">
    <link
      type="text/css"
      rel="stylesheet"
      href="https://dmogdx0jrul3u.cloudfront.net/1.3.7/css/react-select.css"
    >

    <meta name="format-detection" content="telephone=no">
  </div>
</template>
<script>
import { ZoomMtg } from '@zoomus/websdk';

var API_KEY = '4NE4CR13RFe7CXecFs9zOw';
var API_SECRET = 'JGnxEP0qKttg5AgvnXSOu1eyZHrdzTzoDD4t';
export default {
  name: "ZoomFrame",
  data: function() {
    return {
      src: "",
      meetConfig: {},
      signature: {}
    };
  },
  props: {
    nickname: String,
    meetingId: String
  },
  created: function() {

// CDN version default
    let zoomJSLib='https://source.zoom.us/1.8.6/lib';
    let zoomJSAVLib='/av'
    ZoomMtg.setZoomJSLib(zoomJSLib, zoomJSAVLib);

    ZoomMtg.preLoadWasm();

    ZoomMtg.prepareJssdk();
    // Meeting config object
    this.meetConfig = {
      apiKey: API_KEY,
      apiSecret: API_SECRET,
      meetingNumber: this.meetingId,
      userName: this.nickname,
      passWord: "",
      leaveUrl: `./#/teacher/end-meeting/${this.meetingId}`,
      role: 1
    };
    // Generate Signature function
    this.signature = ZoomMtg.generateSignature({
      meetingNumber: this.meetConfig.meetingNumber,
      apiKey: this.meetConfig.apiKey,
      apiSecret: this.meetConfig.apiSecret,
      role: this.meetConfig.role,
      success: function(res) {
        // eslint-disable-next-line
        console.log("success signature: " + res.result);
      }
    });
    // join function
    ZoomMtg.init({
      leaveUrl: `./#/teacher/end-meeting/${this.meetingId}`,
      isSupportAV: true,
      success: () => {
        ZoomMtg.join({
          meetingNumber: this.meetConfig.meetingNumber,
          userName: this.meetConfig.userName,
          signature: this.signature,
          apiKey: this.meetConfig.apiKey,
          userEmail: "email@gmail.com",
          passWord: this.meetConfig.passWord,
          success: function(res) {
            // eslint-disable-next-line
            console.log("join meeting success");
          },
          error: function(res) {
            // eslint-disable-next-line
            console.log(res);
          }
        });
      },
      error: function(res) {
        // eslint-disable-next-line
        console.log(res);
      }
    });
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.join-audio-by-voip{
  z-index: 1000 !important;
}

  .btn-default {
     color: #333;
     background-color: unset !important;
     border: unset !important;
    &:hover{
      color: #333;
      background-color: unset !important;
      border: unset !important;
    }
  }
</style>
