<template>
  <div class="meeting">
    <div class="container-zoom">
      <ZoomFrame :nickname="nickname" :meetingId="meetingId"/>
    </div>
  </div>
</template>

<script>
import ZoomFrame from './components/ZoomFrame.vue'

export default {
  name: 'app',
  data () {
    return {
      meetingId: this.$route.params.id
    }
  },
  components: {
    ZoomFrame
  },
  computed: {
    nickname () {
      return JSON.parse(localStorage.getItem('authUser')).displayName
    }
  }
}
</script>

<style>
.container-zoom {
  width: 70%;
  height: 100%;
}
</style>
