<template>
  <div class="reportsText">
    <div class="container">
      <vs-row>
        <vs-col style="margin-top: 10px" vs-w="4">
          <v-select
            v-model="student"
            :reduce="(subj) => subj"
            :label="$t('basic.nickname')"
            style="width: 90%; margin-top: 15px"
            :options="students"
          >
          </v-select>
        </vs-col>
        <vs-col style="margin-top: 10px" vs-w="4">
          <vs-input
            :label="$t('basic.student_name')"
            type="text"
            v-model="student.name"
          />
        </vs-col>
        <vs-col style="margin-top: 10px" vs-w="4">
          <vs-input
            :label="$t('basic.grade')"
            type="text"
            v-model="student.grade"
          />
        </vs-col>
      </vs-row>
      <!-- Subjects -->
      <vs-row>
        <vs-col
          style="margin-top: 10px"
          v-for="(subject, i) in subjects"
          :key="subject"
          vs-w="4"
        >
          <vs-input
            :label="`${subject.name} ${
              (subjects[i + 1] && subjects[i + 1].name === subject.name) ||
              (subjects[i - 1] && subjects[i - 1].name === subject.name)
                ? `(${subject.credits})`
                : ''
            }`"
            type="number"
            v-model="subject.grade"
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col
          style="margin-top: 10px"
          v-for="subject in noGPASubjects"
          :key="subject"
          vs-w="4"
        >
          <vs-input
            :label="subject.name"
            type="number"
            v-model="subject.grade"
          />
        </vs-col>
      </vs-row>
      <vs-button
        color="#262979"
        style="float: right; margin: 20px"
        @click="printPDF"
        type="filled"
        >{{ $t("basic.print_PDF") }}</vs-button
      >
    </div>
    <div class="container" ref="printMe1">
      <vs-row style="margin: 10px 0" vs-align="center">
        <vs-col vs-justify="start" vs-align="center" vs-w="10">
          <img
            class="logoContainDiv"
            :src="$logoImg"
            :alt="`${$t('basic.Logo')}`"
          />
        </vs-col>
        <vs-col vs-justify="start" vs-align="center" vs-w="2">
          <div class="logoContainAdvanced"></div>
        </vs-col>
      </vs-row>
      <h1 class="text-primary text-center">Quarter 1 - November 2021</h1>
      <vs-row style="margin-top: 30px" class="reportRow" vs-align="center">
        <vs-col vs-w="2">
          <span class="text-primary font-bold"
            >{{ $t("basic.student_name") | text_formatter }}:
          </span>
        </vs-col>
        <vs-col vs-w="9">
          {{ student.name }}
        </vs-col>
      </vs-row>
      <vs-row class="reportRow" vs-align="center">
        <vs-col vs-w="2">
          <span class="text-primary font-bold"
            >{{ $t("basic.grade") | text_formatter }}:
          </span>
        </vs-col>
        <vs-col vs-w="9">
          {{ student.grade }}
        </vs-col>
      </vs-row>
      <vs-table style="margin-top: 25px" :data="gradedSubjects">
        <template slot="thead">
          <vs-th> {{ $t("basic.subject") | text_formatter }} </vs-th>
          <vs-th> {{ $t("basic.percentage") | text_formatter }} </vs-th>
          <vs-th> {{ $t("basic.grade") | text_formatter }} </vs-th>
          <vs-th> {{ $t("basic.credits") | text_formatter }} </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr v-for="(val, i) in gradedSubjects" :key="i">
            <vs-td>
              {{ val.name }}
            </vs-td>
            <vs-td> {{ val.grade }}% </vs-td>
            <vs-td>
              {{ computeGrade(val.grade).grade }}
            </vs-td>
            <vs-td> {{ val.credits }} CD </vs-td>
          </vs-tr>
          <vs-tr class="table-footer">
            <vs-td>
              <h5 class="font-bold text-primary">{{ $t("basic.total") }}</h5>
            </vs-td>
            <vs-td>
              <h5 class="font-bold text-primary">{{ totalSubjects }}%</h5>
            </vs-td>
            <vs-td>
              <h5 class="font-bold text-primary">
                {{ computeGrade(totalSubjects).grade }}
              </h5>
            </vs-td>
            <vs-td>
              <h5 class="font-bold text-primary">{{ totalCredits }} CD</h5>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <h3 class="text-center font-bold my-10">
        {{ $t("basic.final_GPA") }}:
        {{ computeGrade(totalSubjects).points.toFixed(2) }}
      </h3>
      <vs-table :data="gradedSubjectsnoGPA">
        <template slot="thead">
          <vs-th> {{ $t("basic.subject") }} </vs-th>
          <vs-th> {{ $t("basic.percentage") }} </vs-th>
          <vs-th> {{ $t("basic.grade") }} </vs-th>
          <vs-th> {{ $t("basic.credits") }} </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr v-for="(val, key) in gradedSubjectsnoGPA" :key="key">
            <vs-td>
              {{ val.name }}
            </vs-td>
            <vs-td> {{ val.grade }}% </vs-td>
            <vs-td>
              {{ computeGrade(val.grade).grade }}
            </vs-td>
            <vs-td> {{ val.credits }} CD </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-row class="infoBox">
        (59<= “F”) ,(60>= ”D-“ <=63), (64>= ”D“ <=66), (67>= ”D+“ <=68), (70>=
        ”C-“ <=72), (73>= ”C“ <=76), (77>= ”C+“ <=79), (80>= ”B-“ <=82), (83>=
        ”B“ <=86), (87>= ”B+“ <=89), (90>= ”A-“ <=92), (93>= ”A“ <=96), (97>=
        ”A+“ <=100)
      </vs-row>
      <vs-row style="margin-top: 30px" vs-align="center">
        <vs-col vs-w="9">
          <p>Doaa Sabry</p>
          <p class="text-primary font-bold">American Coordinator</p>
        </vs-col>
        <vs-col vs-w="2">
          <p>Yasmin Hamoda</p>
          <p class="text-primary font-bold">Principal</p>
        </vs-col>
      </vs-row>
      <vs-row class="reportRow" vs-align="center">
        <vs-col vs-w="12">
          <p class="text-center">{{ $t("basic.powered_by_schoolia") }}</p>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
// import jsPDF from 'jspdf'
import Axios from 'axios'
import vSelect from 'vue-select'
import FlatPickr from 'vue-flatpickr-component'

export default {
  components: {
    'v-select': vSelect,
    FlatPickr
  },
  data: () => ({
    student: { name: '', grade: '' },
    students: [],
    subjects: [
      {
        name: 'English',
        grade: '',
        credits: 1
      },
      {
        name: 'Biology',
        grade: '',
        credits: .5
      },
      {
        name: 'Physics',
        grade: '',
        credits: 1
      },
      {
        name: 'Science',
        grade: '',
        credits: 1
      },
      {
        name: 'Mathematics',
        grade: '',
        credits: 1
      },
      {
        name: 'Math - Statistics',
        grade: '',
        credits: 1
      },
      {
        name: 'Math - Calculus',
        grade: '',
        credits: 1
      },
      {
        name: 'English Social Studies',
        grade: '',
        credits: 0.5
      },
      {
        name: 'French / German ',
        grade: '',
        credits: 0.5
      },
      {
        name: 'French / German ',
        grade: '',
        credits: 1
      },
      {
        name: 'History',
        grade: '',
        credits: 0.5
      },
      {
        name: 'Geography',
        grade: '',
        credits: 0.5
      },

      {
        name: 'Art ',
        grade: '',
        credits: 0.5
      },
      {
        name: 'Art',
        grade: '',
        credits:1
      },
      {
        name: 'ICT',
        grade: '',
        credits: 0.5
      },

      {
        name: 'PE',
        grade: '',
        credits: 0.5
      },
      {
        name: 'PE',
        grade: '',
        credits: 1
      },
      {
        name: 'Music',
        grade: '',
        credits: 0.5
      },
      {
        name: 'Business',
        grade: '',
        credits: 1
      },
      {
        name: 'Computer Science',
        grade: '',
        credits: 1
      },
      {
        name: 'Visual Arts',
        grade: '',
        credits: 1
      },
      {
        name: 'Psychology',
        grade: '',
        credits: 1
      },
      {
        name: 'Chemistry',
        grade: '',
        credits: 1
      },
      {
        name: 'Economics',
        grade: '',
        credits: 1
      },
      {
        name: 'ESS',
        grade: '',
        credits: 0.5
      },
      {
        name: 'ICT',
        grade: '',
        credits: 1
      }
    ],
    noGPASubjects: [
      {
        name: 'Arabic',
        grade: '',
        credits: 1
      },
      {
        name: 'Arabic social Studies',
        grade: '',
        credits: 0.5
      }

    ],
    grades: [],
    date: '',
    configdatePicker: {
      dateFormat: 'M d, yy'
    },

    // pdf print
    printElement1: null,
    printElement2: null,
    printElement3: null,
    printElement4: null,
    printElement5: null,
    printElementDimension: {
      width: null,
      height: null
    }
  }),
  mounted () {
    this.getAllData()
  },
  methods: {
    async printPDF () {
      this.printElement1 = this.$refs.printMe1
      const printElement1Dimension = {
        width: this.printElement1.clientWidth * 0.5,
        height: this.printElement1.clientHeight * 0.5
      }
      const page1 = await this.html2PdfBase64NoPrint(this.printElement1)
      const imgs = [page1]
      const dim = [printElement1Dimension]
      this.imgs2Pdf(imgs, dim, this.pdfOptions)
    },
    computeGrade (grade) {
      let str = {
        grade: '',
        points: 0
      }
      if (grade >= 97) {
        str = { grade: 'A+', points: 4.0 }
      } else if (97 > grade && grade >= 93) {
        str = { grade: 'A', points: 4.0 }
      } else if (93 > grade && grade >= 90) {
        str = { grade: 'A-', points: 3.67 }
      } else if (90 > grade && grade >= 87) {
        str = { grade: 'B+', points: 3.33 }
      } else if (87 > grade && grade >= 83) {
        str = { grade: 'B', points: 3.0 }
      } else if (83 > grade && grade >= 80) {
        str = { grade: 'B-', points: 2.76 }
      } else if (80 > grade && grade >= 77) {
        str = { grade: 'C+', points: 2.33 }
      } else if (77 > grade && grade >= 73) {
        str = { grade: 'C', points: 2.0 }
      } else if (73 > grade && grade >= 70) {
        str = { grade: 'C-', points: 1.76 }
      } else if (70 > grade && grade >= 67) {
        str = { grade: 'D+', points: 1.33 }
      } else if (67 > grade && grade >= 63) {
        str = { grade: 'D', points: 1.0 }
      } else if (63 > grade && grade >= 60) {
        str = { grade: 'D-', points: 0.76 }
      } else if (60 > grade && grade) {
        str = { grade: 'F', points: 0.0 }
      }
      return str
    },
    getAllData () {
      Axios.get('divisions/2/students')
        .then((res) => {
          console.log('students', res)
          this.students = res.data
          this.student = res.data[0]
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  },
  computed: {
    gradedSubjects () {
      const result = this.subjects.filter(
        (obj) => !Object.values(obj).includes('')
      )
      return result
    },
    gradedSubjectsnoGPA () {
      const result = this.noGPASubjects.filter(
        (obj) => !Object.values(obj).includes('')
      )
      return result
    },
    totalSubjects () {
      let total = 0
      let credits = 0
      this.gradedSubjects.forEach((sub) => {
        total += parseInt(sub.grade * (sub.credits * 2))
        credits += parseInt(sub.credits * 2)
      })
      const avg = total / credits
      return avg.toFixed(2)
    },
    totalCredits () {
      let credits = 0
      this.gradedSubjects.forEach((sub) => {
        credits += parseInt(sub.credits * 2)
      })
      return credits / 2
    },
    pdfOptions () {
      const opt = {
        orientation: 'portrait',
        unit: 'pt',
        fileName: this.student.nickname,
        format: {}
      }
      return opt
    }
  }
}
</script>

<style lang="scss">
@import "./pdfStyle";

.reportRow {
  padding: 6px 0;
}

.logoContainDiv {
  // background-image: url("../../assets/images/logo/logo.png");
  height: 60px;
}
.logoContainAdvanced {
  background-image: url("../../assets/images/pages/Picture1-removebg-preview.png");
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
}

.reportsText {
  .infoBox {
    border: 1px solid $color-border;
    margin-top: 30px;
    padding: 5px;
  }
  .table-footer td,
  th {
    background: rgba($color-border, 0.4);
  }
}
</style>
