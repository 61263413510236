<template>
    <div>
        Ending Zoom Meeting ...
    </div>
</template>
<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository = {
  Zoom: RepositoryFactory.get('TeachersModule', 'Zoom')
}
export default {
  created () {
    this.endMeeting()
  },
  methods: {
    endMeeting () {
      const id = this.$route.params.id
      TeacherRepository.Zoom.endZoomMeeting(id).then((res) => {
        /* window.location.href = '/#/landing/home'*/
        /*this.$router.push({ path: 'onlineClass' });*/
        window.close()
      })
    }
  }
}
</script>
